export default {
  data() {
    return {
      name: "",
      lastname: "",
      email: "",
      phoneNumber: "",
      texts: "",
      screenWidth: 0,
      bLoading: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.close();
  },
  beforeMount() {
    this.texts = FILE.subadminTexts[this.selectLanguage];
  },
  methods: {
    editSubadmin() {
      this.bLoading = true;

      const params = {
        sName: this.name,
        sLastname: this.lastname,
        sEmail: this.email,
        sPhoneNumber: this.phoneNumber,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/users/${this.$store.state.arrEditSubadmin.sAdministratorId}`,
        params,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.close();
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    subadminId() {
      //post credentials and get access token from laravel backend
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/administrators/${this.$store.state.arrEditSubadmin.sAdministratorId}`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {
            iPageNumber: 1,
          },
        }
      )
        .then((response) => {
          this.name = response.data.results.sName;
          this.lastname = response.data.results.sLastname;
          this.email = response.data.results.sEmail;
          this.phoneNumber = response.data.results.sPhoneNumber;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    close() {
      this.$store.commit("setDialogEditSubadmin", {
        active: false,
        arr: [],
      });
      this.name = "";
      this.lastname = "";
      this.email = "";
      this.phoneNumber = "";
    },
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    dialogEditSubadmin() {
      return this.$store.state.dialogEditSubadmin;
    },
    validateForm() {
      return (
        this.name !== "" &&
        this.lastname !== "" &&
        this.email !== "" &&
        this.phoneNumber !== "" &&
        this.name !== null &&
        this.lastname !== null &&
        this.email !== null &&
        this.phoneNumber !== null
      );
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.subadminTexts[this.selectLanguage];
      }
    },
    dialogEditSubadmin: function() {
      if (this.dialogEditSubadmin) {
        this.$store.commit("setMenuSidebar", { active: false });

        this.texts = FILE.subadminTexts[this.selectLanguage];
        this.subadminId();
      }
    },
  },
};
